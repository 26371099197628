import styles from "../ReleaseNote.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import parse from "html-react-parser";

const ReleaseUpdate = ({ updatetitle, updatedesc, updateimg, id }) => {
  return (
    <div className={`${styles.updatemaincont}`}>
      <Grid container spacing={2}>
        <Grid className={`${styles.updateleft}`} item lg={2} md={2} sm={6} xs={6}
          style={{ backgroundImage: `url(${updateimg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", marginTop: "20px" }}
        >
        </Grid>
        <Grid className={`${styles.updateright}`} item lg={10} md={10} sm={12} xs={12}>
          <div className={`${styles.releaseupdateinfo}`}>
            <h4>{parse(updatetitle)}</h4>
            <p>{parse(updatedesc)}</p>
            <Link to={`/release-note/${id}`}>See more details</Link>
          </div>
        </Grid>
      </Grid>
      <hr />
    </div>
  );
};
ReleaseUpdate.PropTypes = {
  updateimg: PropTypes.any,
  updatetitle: PropTypes.string,
  updatedesc: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ReleaseUpdate;
