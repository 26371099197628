import styles from "../releasenote/ReleaseNote.module.css";
import ReleaseUpdate from "./component/ReleaseUpdate";
import ReleaseUpdateImg from "./../../assets/images/blogthumbnail.jpg";
import { useEffect, useState } from "react";
import PageLoader from "../../components/PageLoader";
import Pagination from "@mui/material/Pagination";
// import parse from "html-react-parser";

const ReleaseNote = () => {
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const updatesPerPage = 5;
  const lastIndex = currentPage * updatesPerPage;
  const firstIndex = lastIndex - updatesPerPage;
  const currentUpdates = updates.slice(firstIndex, lastIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchUpdates = async () => {
    try {
      const response = await fetch(
        "https://shelf21blog.com.ng/blog/?rest_route=/wp/v2/posts&_embed"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch updates");
      }
      const data = await response.json();
      setUpdates(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUpdates();
  }, []);

  return (
    <div>
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.releaseheader}`}>
              <h1>See the releases and Updates available to you.</h1>
              <p>
                Empowering Schools, Engaging Students, and Connecting Parents,
                all in one platform.
              </p>
              <div className={`${styles.releaseupdate}`}>
                <p>
                  {loading
                    ? "Loading latest update..."
                    : updates[0]?.date
                      ? `Latest release was updated on ${new Date(
                        updates[0]?.date
                      ).toLocaleDateString()}.`
                      : "No updates available."}
                </p>
                <span>
                  {/* <Link to="/blog">Click to see release.</Link> */}
                </span>
              </div>
            </div>

            {error && <p className={`${styles.error}`}>{error}</p>}

            {loading && <PageLoader />}

            {!loading &&
              currentUpdates.map((update) => (
                <ReleaseUpdate
                  key={update.id}
                  id={update.id}
                  updateimg={
                    update._embedded?.["wp:featuredmedia"]?.[0]?.source_url || ReleaseUpdateImg
                  }
                  updatetitle={update.title.rendered}
                  // updatedesc={update.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")} // Stripping HTML tags\
                  updatedesc={update.excerpt.rendered}
                />
              ))}

            {!loading && updates.length === 0 && (
              <p>No updates to display at the moment.</p>
            )}

            {!loading && updates.length > 0 && (
              <div className={`${styles.pagination}`}>
                <Pagination
                  count={Math.ceil(updates.length / updatesPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  className="custom-pagination"
                  shape="rounded"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleaseNote;
