import "./App.css";
import Home from "./pages/home/index";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="">
      <ScrollToTop />
      <Home />
    </div>
  );
}

export default App;
