import MajorFeature from "./component/MajorFeature";
// import OtherFeatures from "./component/OtherFeatures";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./ReleaseNote.module.css";
import PageLoader from "../../components/PageLoader";
import thumbnail from "../../assets/images/thumb.png";
import parse from "html-react-parser";

const UpdateInfomation = () => {
  const { id } = useParams(); // Extract `id` from the URL
  const [releaseNote, setReleaseNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReleaseNote = async () => {
    try {
      const response = await fetch(
        `https://releasenote.com.ng/blog/?rest_route=/wp/v2/posts/${id}&_embed`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch release note");
      }
      const data = await response.json();
      setReleaseNote(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchReleaseNote();
  }, [id]);

  if (loading) return <PageLoader />;
  if (error) return <p className={styles.error}>{error}</p>;

  return (
    <div>
      {releaseNote && (
        <div className="release_div">
          <div className="thumbnail" style={{ backgroundImage: `url(${releaseNote._embedded?.["wp:featuredmedia"]?.[0]?.source_url || thumbnail})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
            {/* <img
              src={
                releaseNote._embedded?.["wp:featuredmedia"]?.[0]?.source_url || thumbnail
              }
            /> */}
          </div>
          <MajorFeature
            versiontitle={parse(releaseNote.title.rendered)}
            releasedate={new Date(releaseNote.date).toLocaleDateString()}
            versiondesc={parse(releaseNote.excerpt.rendered)}
            majorfttitle={parse(releaseNote.title.rendered)}
            // majorftdesc="Description for the major feature"
            // majorftsubtitle="Key Sub Features"
          />
          <div className="rendered">
            <div
              className="rendered_div"
              dangerouslySetInnerHTML={{
                __html: releaseNote.content?.rendered || "<p>No Content Available</p>"
              }}
            />
          </div>
          {/* <OtherFeatures
            otherftsubtitle="1. Key Sub Features"
            otherftsubtitletwo="2. Additional Sub Features"
          /> */}
        </div>
      )}
    </div>
  );
};

export default UpdateInfomation;
