import BackButton from "../../../components/Button/BackButton";
import styles from "./Feature.module.css";
import PropTypes from "prop-types";
import parse from "html-react-parser";
// import MajorFeatureIcon from "../../../assets/images/featureicon.svg";
// import CircleIcon from "@mui/icons-material/Circle";
// import MajorFeatureImage from "../../../assets/images/featureimage.png";

const MajorFeature = ({ versiontitle, releasedate }) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.majorfeaturecont}`}>
        <div className={`${styles.majorfeatureheader} margin-inline-24`}>
          <div className={`${styles.versionheader} flex space-between`}>
            <h3> <BackButton /> {parse(versiontitle)}</h3>
            <p>{releasedate}</p>
          </div>
          {/* <p className={`${styles.versioncontent}`}>{versiondesc}</p> */}
        </div>
        {/* <h4 className={`${styles.majorfeature}`}>Full Blog Details</h4> */}
        <div className={`${styles.majorfeaturecontent} margin-inline-24`}>
          {/* <img src={MajorFeatureIcon} alt="Major Icon" />
          <h4>{majorfttitle}</h4>
          <p>{majorftdesc}</p>
          <h5>{majorftsubtitle}</h5> */}
          {/* <div className={`${styles.subfeaturecont}`}>
            <p>Here are some of the perks you get to enjoy with this feature.</p>
            {features.map((feature, index) => (
              <div key={index} className="flex gap-8 margin-top-20">
                <div className={`${styles.circleforparagraph}`}>
                  <CircleIcon sx={{ height: "6px", color: "black" }} />
                </div>
                <p>{feature}</p>
              </div>
            ))}
            <div>
              <img src={MajorFeatureImage} alt="Feature" width="100%" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
MajorFeature.PropTypes = {
  versiontitle: PropTypes.string,
  releasedate: PropTypes.string,
  versiondesc: PropTypes.string,
  majorftdesc: PropTypes.string,
  majorfttitle: PropTypes.string
};

export default MajorFeature;
