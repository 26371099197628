import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div
      className="back-button"
      onClick={() => navigate(-1)}  // Go back to the previous page
    >
      <span>
        <FaArrowLeft />
      </span>

    </div>
  );
};

export default BackButton;
