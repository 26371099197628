import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FeatureRequest from "./pages/solutions/FeatureRequest";
import Feature from "./pages/features/index";
import PricingPage from "./pages/pricing/index";
import PrivacyPolicy from "./pages/privacypolicy/index";
import ReleaseNote from "./pages/releasenote/index";
import UpdateInfomation from "./pages/releasenote/UpdateInfomation";
import Blog from "./pages/blog/index";
import BlogInfomation from "./pages/blog/UpdateInfomation";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./pages/notfound/Index";
import Header from "./components/Header/index";
import Footer from "./components/Footer/index";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route index element={<App />} />
        <Route path="/feature-request" element={<FeatureRequest />} />
        <Route path="/feature" element={<Feature />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/release-note" element={<ReleaseNote />} />
        <Route path="/release-note/:id" element={<UpdateInfomation />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogInfomation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
