import { useState } from "react";
import Logo from "../../assets/images/shelf21.svg";
import { useNavigate, Link } from "react-router-dom";
import {
  // AppBar,
  // Button,
  Tab,
  Tabs,
  Toolbar,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Button from "../Button/ButtonSm";
import styles from "./Header.module.css";
import Grid from "@mui/material/Grid";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
// import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import StarIcon from "@mui/icons-material/Star";
// import SchoolIcon from "@mui/icons-material/School";
import DrawerComp from "./Drawer";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState();
  const [devloperdd, setDeveloperDD] = useState();
  const opensolutionTab = Boolean(anchorEl);
  const opendevTab = Boolean(devloperdd);
  const [value, setValue] = useState("");
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  // const handleClickDeveloper = (event) => {
  //   setDeveloperDD(event.currentTarget);
  // };
  const handleLoginClick = () => {
    window.open(`${process.env.REACT_APP_URL}/auth/login`, "_blank");
  };

  const labelToUpperCase = (label) => {
    return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  };

  const handleCloseDeveloper = () => {
    setDeveloperDD();
  };

  const handleClickSolution = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSolution = () => {
    setAnchorEl();
  };

  return (
    <>
      <div className={`${styles.headeroverall}`}>
        <div className={`${styles.container}`}>
          <Toolbar sx={{ "@media (max-width: 430px)": { bgcolor: "black" } }}>
            <Link to="/">
              <img
                src={Logo}
                className={`${styles.logo}`}
                sx={{ transform: "scale(2)" }}
                alt=""
              />
            </Link>

            {isMatch ? (
              <>
                <DrawerComp />
              </>
            ) : (
              <>
                <Tabs
                  className=""
                  sx={{
                    marginLeft: "auto",
                    "& button": {
                      fontSize: "16px !important",
                      fontWeight: "400",
                      lineHeight: "29px"
                    },
                    "& .Mui-selected": {
                      color: "#0065FF",
                      borderBottom: "2px solid #0065FF"
                    }
                  }}
                  indicatorColor="#0065FF"
                  value={value}
                  aria-label=""
                  onChange={(e, value) => setValue(value)}
                >
                  <Tab
                    className="list-styles"
                    value="1"
                    label={labelToUpperCase("GUIDE")}
                    sx={{ textTransform: "none" }}
                    onClick={() => window.open("https://www.youtube.com/playlist?list=PLNGFanEgGNga_3dxFS9AKr-HvkypE9a-l", "_blank")}

                  />
                  <Tab
                    className="list-styles"
                    value="2"
                    aria-controls="basic-menu"
                    open={opensolutionTab}
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={handleClickSolution}
                    label={labelToUpperCase("Solutions")}
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    onClick={() => navigate("/release-note")}
                    label={labelToUpperCase("Release notes")}
                    sx={{ textTransform: "none" }}
                    className="list-styles"
                    value="3"
                  />
                  <Tab
                    onClick={() => navigate("/feature")}
                    className="list-styles"
                    value="4"
                    label={labelToUpperCase("Features")}
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    onClick={() => navigate("/blog")}
                    label={labelToUpperCase("Blog")}
                    sx={{ textTransform: "none" }}
                    className="list-styles"
                    value="5"
                  />
                  {/* <Tab
                    onClick={() => navigate("/pricing")}
                    className="list-styles"
                    value="5"
                    label={labelToUpperCase("Pricing")}
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    className="list-styles"
                    value="6"
                    aria-controls="developer-menu"
                    open={opendevTab}
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={handleClickDeveloper}
                    label={labelToUpperCase("Developers")}
                    sx={{ textTransform: "none" }}
                  /> */}
                </Tabs>
                {/* Solutions Dropdown */}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={opensolutionTab}
                  onClose={handleCloseSolution}
                >
                  <div className={`${styles.overalldropdown}`}>
                    <div className={`${styles.solutionheader}`}>
                      <h6>Solutions</h6>
                      <div className={`${styles.divider}`}>
                        <hr />
                      </div>
                    </div>
                    <div className={`${styles.navbar_drpdwn}`}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <MenuItem sx={{ paddingLeft: "0px" }}>
                            <div onClick={() => window.open("https://www.corpine.com/#contact", "_blank")} className="flex gap-8 align-item-start space-left">
                              <LightbulbIcon className="icon-green little-margin-top" />
                              <div className={`${styles.solutionheader}`}>
                                <h6>Custom Solutions</h6>
                                <p>Request a solution tailored to you</p>
                              </div>
                            </div>
                          </MenuItem>
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                          <MenuItem sx={{ paddingLeft: "0px" }} onClick={() => navigate("/feature-request")}>
                            <div className="flex gap-8 align-item-start space-left">
                              <HistoryEduIcon className="icon-yellow little-margin-top" />
                              <div className={`${styles.solutionheader}`}>
                                <h6>Feature Request</h6>
                                <p>Request a feature for your organization</p>
                              </div>
                            </div>
                          </MenuItem>
                        </Grid> */}
                        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                          <MenuItem sx={{ paddingLeft: "0px" }}>
                            <div className="flex gap-8 align-item-start">
                              <SchoolIcon className="icon-blue little-margin-top" />
                              <div className={`${styles.solutionheader}`}>
                                <h6>21_Uni</h6>
                                <p>Request a Demo of Uni_21</p>
                              </div>
                            </div>
                          </MenuItem>
                        </Grid> */}
                      </Grid>
                    </div>
                  </div>
                </Menu>
                {/* Developer Dropdown */}
                <Menu
                  id="developer-menu"
                  anchorEl={devloperdd}
                  open={opendevTab}
                  onClose={handleCloseDeveloper}
                >
                  <MenuList sx={{ maxWidth: "853px !important", paddingTop: "0px", paddingBottom: "0px" }}>
                    <div className={`${styles.overalldropdown}`}>
                      <div className={`${styles.solutionheader}`}>
                        <h6>Developers</h6>
                        <div className={`${styles.divider}`}>
                          <hr />
                        </div>
                      </div>
                      <div className={`${styles.navbar_drpdwn}`}>
                        <Grid container spacing={2}>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <MenuItem sx={{ paddingLeft: "0px" }}>
                              <div className="flex gap-8 align-item-start space-left">
                                <StickyNote2Icon className="icon-blue little-margin-top" />
                                <div className={`${styles.solutionheader}`}>
                                  <h6>Get Started</h6>
                                  <p>View all developer resources</p>
                                </div>
                              </div>
                            </MenuItem>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <MenuItem sx={{ paddingLeft: "0px" }}>
                              <div className="flex gap-8 align-item-start space-left">
                                <StickyNote2Icon className="icon-yellow little-margin-top" />
                                <div className={`${styles.solutionheader}`}>
                                  <h6>Documentation</h6>
                                  <p>See all developer docs</p>
                                </div>
                              </div>
                            </MenuItem>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <MenuItem sx={{ paddingLeft: "0px" }}>
                              <div className="flex gap-8 align-item-start">
                                <CodeOffIcon className="icon-blue little-margin-top" />
                                <div className={`${styles.solutionheader}`}>
                                  <h6>API Reference</h6>
                                  <p>Reference all api endpoints</p>
                                </div>
                              </div>
                            </MenuItem>
                          </Grid>
                          <Grid item lg={5} md={5} sm={6} xs={12}>
                            <MenuItem sx={{ paddingLeft: "0px" }}>
                              <div className="flex gap-8 align-item-start">
                                <StarIcon className="icon-green little-margin-top" />
                                <div className={`${styles.solutionheader}`}>
                                  <h6>API Status</h6>
                                  <p>See the status of the API requested</p>
                                </div>
                              </div>
                            </MenuItem>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </MenuList>
                </Menu>
                <Button link={handleLoginClick} title="Login" />
                {/* <Button sx={{ marginLeft: "auto" }} variant="contained">
                  Login
                </Button> */}
              </>
            )}
          </Toolbar>
        </div>
      </div>
    </>
  );
};

export default Header;
