import styles from "./Pricing.module.css";
import ButtonArr from "../../components/Button/ButtonArr";
import { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import PriceCard from "../../components/Pricing/components/PriceCard";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import ComingSoon from "../../assets/images/comingsoontag.svg";

// --| Rewrite the comingsoon tag on each table

// USED TO STYLE THE TABLE
const tableCellHeaderStyle = { fontWeight: "400", fontSize: "18px", lineHeight: "21.6px", borderBottom: "0px", color: "rgba(169, 169, 169, 1)", padding: "0px 16px 35px 16px" };
const tableSubDescStyle = { fontWeight: "700", fontSize: "30px", lineHeight: "36px", borderBottom: "0px" };
const tableSubHeaderStyle = { fontWeight: "700", fontSize: "20px", lineHeight: "24px", padding: "50px 16px 16px 16px", borderBottom: "0px" };

const PricePage = () => {
  const [value, setValue] = useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const createData = (name, basic, premium, enterprise) => {
    return { name, basic, premium, enterprise };
  };

  const tableone = [
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Members</p> <HelpOutlineIcon height="17px" /></span>, 1, "Up to 3", "Unlimited"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Workspace roles</p>  <HelpOutlineIcon height="17px" /></span>, "Admin only", "Admin only", "Admin & collaborator"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Guests</p> <HelpOutlineIcon height="17px" /></span>, "", "", ""),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Live collaboration</p> <HelpOutlineIcon height="17px" /></span>, "", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Integration of sub-brands</p> <HelpOutlineIcon height="17px" /></span>, "", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Access to standard templates</p> <HelpOutlineIcon height="17px" /></span>, <DoneIcon />, <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Access to pro templates</p> <HelpOutlineIcon height="17px" /></span>, "", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Custom designed templates</p> <HelpOutlineIcon height="17px" /></span>, "", "", <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Asset library</p> <HelpOutlineIcon height="17px" /></span>, <DoneIcon />, <DoneIcon />, <DoneIcon />)
  ];
  const tabletwo = [
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Import own templates</p><HelpOutlineIcon height="17px" /></span>, "Static only", "Static & animated", "Static & animated"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Workspace roles</p> <HelpOutlineIcon height="17px" /></span>, "Max. 25MB", "Max. 100 MB", "Unlimited"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Video upload</p>  <HelpOutlineIcon height="17px" /></span>, "Up to 3 per month", "Up to 10 per month", "Unlimited"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Asset storage</p> <HelpOutlineIcon height="17px" /></span>, "Up to 5GB", "Up to 10 GB", "Unlimited"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Access to stock image library</p> <HelpOutlineIcon height="17px" /></span>, "", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Access to stock sound library</p> <HelpOutlineIcon height="17px" /></span>, "", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Custom Fonts</p> <HelpOutlineIcon height="17px" /></span>, "Selection of 30 Fonts", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Logo variants</p> <HelpOutlineIcon height="17px" /></span>, "Up to 2", "", "Unlimited", "Unllimited"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Logo outro</p> <HelpOutlineIcon height="17px" /></span>, "Static only", "Selection of 5 motions", "Custom / LottieFile import")
  ];
  const tablethree = [
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Export files</p> <HelpOutlineIcon height="17px" /></span>, "PNG, PDF, MP4", "PNG, JPEG, PDF, MP4", "PNG, JPEG, PDF, GIF, MP4"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Multiple dimensions</p> <HelpOutlineIcon height="17px" /></span>, "1:1 and 9:16", "1:1, 1,91:1, 4:5, 9:16", "Unlimited"),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Video upload</p> <HelpOutlineIcon height="17px" /></span>, "", "", <DoneIcon />)
  ];
  const tablefour = [
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Dedicated account manager</p> <HelpOutlineIcon height="17px" /></span>, "", "", <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Priority support</p> <HelpOutlineIcon height="17px" /></span>, "", <DoneIcon />, <DoneIcon />),
    createData(<span className="flex gap-8 align-item-center"><p className={`${styles.tabledata}`}>Access to help center</p> <HelpOutlineIcon height="17px" /></span>, <DoneIcon />, <DoneIcon />, <DoneIcon />)
  ];

  return (
    <div>
      <div className={`${styles.herosectionbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.priceheader}`}>
              <h1>
              With little cash you can get started with managing your school and
              teams.
              </h1>
              <h4>
              Empowering Schools, Engaging Students, and Connecting Parents, all
              in one platform.
              </h4>
              <div className={`${styles.getstartedbtn}`}>
                <ButtonArr title="Get Started" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* PRICING */}
      <div className={`${styles.pricingcontainer}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.pricingheader}`}>
              <h3>Shelf21 Pricing Options</h3>
              <p>
                Record and report on safeguarding information including
                background checks, and safer recruitment trainingRecord and
                report on safeguarding.
              </p>
            </div>
            <div className={`${styles.shelfpricing}`}>
              <TabContext value={value}>
                <Box
                  sx={{
                    maxWidth: "787px",
                    margin: "auto",
                    bgcolor: "#E6F0FF",
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "30px",
                    color: "#0065FF"
                  }}
                >
                  <Tabs
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { display: "none" }
                    }}
                    value={value}
                    centered
                    sx={{
                      "& .MuiTab-root.Mui-selected": {
                        background: "#0065FF",
                        color: "#FFF",
                        borderRadius: "8px"
                      },
                      "& button": {
                        color: "#0065FF",
                        fontSize: "20px",
                        fontWeight: "700",
                        paddingInline: "78px"
                      },
                      "& button:hover": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:active": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:focus": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:not(:last-child)": {
                        marginRight: "8px"
                      }
                    }}
                  >
                    <Tab label="Monthly" value="1" />
                    <Tab label="Quaterly" value="2" />
                    <Tab label="Yearly" value="3" />
                  </Tabs>
                </Box>
                <TabPanel value="1">
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N10,000"
                        payType="Month"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N10,000"
                        payType="Month"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N10,000"
                        payType="Month"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N26,000"
                        payType="Quater"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N36,000"
                        payType="Quater"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N40,000"
                        payType="Quater"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N109,000"
                        payType="Year"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N130,000"
                        payType="Year"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <PriceCard
                        amount="N150,000"
                        payType="Year"
                        planType="Basic Plan"
                        planDescription="Record and report on safeguarding information including background checks."
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
      {/* PLANS AND FEATURES */}
      <div className={`${styles.container}`}>
        <div className={`${styles.contmargin}`}>
          <div className={`${styles.planandfeatures}`}>
            <div className={`${styles.compareplansheader}`}>
              <h3>Compare Plans</h3>
              <p>
                  Choose a subscription that fits your current need and get
                  right on it.
              </p>
            </div>
            <TableContainer component={Paper} sx={{ boxShadow: "0px 0px 0px 0px" }}>
              <Table sx={{ minWidth: 650, borderColor: "white" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={tableSubDescStyle}></TableCell>
                    <TableCell align="center" sx={tableSubDescStyle}>Basic</TableCell>
                    <TableCell align="center" sx={tableSubDescStyle}>Premium</TableCell>
                    <TableCell align="center" sx={tableSubDescStyle}>Enterprise</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" sx={tableCellHeaderStyle}></TableCell>
                    <TableCell align="center" sx={tableCellHeaderStyle}>On request</TableCell>
                    <TableCell align="center" sx={tableCellHeaderStyle}>On request</TableCell>
                    <TableCell align="center" sx={tableCellHeaderStyle}>On request</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px", borderBottom: "0px" }}>WORKSPACE MANAGEMENT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableone.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:nth-of-type(odd)": { backgroundColor: "#F9F9FB" }
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ borderBottom: "0px" }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.basic}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.premium}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.enterprise}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* SECOND TABLE */}
                <TableHead sx={{ marginTop: "50px" }}>
                  <TableRow>
                    <TableCell sx={tableSubHeaderStyle}>SCHOOL BRANDING</TableCell>
                    <TableCell align="center" sx={tableSubDescStyle}></TableCell>
                    <TableCell align="center" sx={tableSubDescStyle}></TableCell>
                    <TableCell align="center" sx={tableSubDescStyle}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabletwo.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:nth-of-type(odd)": { backgroundColor: "#F9F9FB" }
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ borderBottom: "0px" }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.basic}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.premium}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.enterprise}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* THIRD TABLE */}
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableSubHeaderStyle}>EXPORTS</TableCell>
                    <TableCell align="center" sx={tableSubHeaderStyle}></TableCell>
                    <TableCell align="center" sx={tableSubHeaderStyle}></TableCell>
                    <TableCell align="center" sx={tableSubHeaderStyle}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tablethree.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:nth-of-type(odd)": { backgroundColor: "#F9F9FB" }
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ borderBottom: "0px" }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.basic}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.premium}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.enterprise}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* FOURTH TABLE */}
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableSubHeaderStyle}>SUPPORTS</TableCell>
                    <TableCell align="center" sx={tableSubHeaderStyle}></TableCell>
                    <TableCell align="center" sx={tableSubHeaderStyle}></TableCell>
                    <TableCell align="center" sx={tableSubHeaderStyle}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tablefour.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:nth-of-type(odd)": { backgroundColor: "#F9F9FB" }
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ borderBottom: "0px" }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.basic}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.premium}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: "0px" }}>{row.enterprise}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePage;
