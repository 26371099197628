import wrong from "../../assets/images/wrong.svg";
import ButtonArr from "../../components/Button/ButtonArr";
import ScrollToTop from "../../components/ScrollToTop";
import "./nonFound.css";
const Index = () => {
  return (
    <div>
      <ScrollToTop />
      <div className="not_found">
        <div className="not_main">
          <img src={wrong} alt="404 Not Found" className="not_img" />
          <h1 className="not_title">Opps, we can’t find this page</h1>
          <p className="not_text">
          Sorry, the page you're looking for doesn't exist.
          </p>
          <ButtonArr title="Return to homepage" link="/" />
        </div>
      </div>
    </div>
  );
};

export default Index;
