import styles from "./FeaturePage.module.css";
// import NorthEastIcon from "@mui/icons-material/NorthEast";
// import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
// import PriceCard from "../../components/Pricing/components/PriceCard";
// eslint-disable-next-line no-duplicate-imports
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AllFeatures from "./components/AllFeatures";
import FeatureImg from "../../assets/images/featureimg.png";
import classImg from "../../assets/images/imageclass.png";
import subjectimg from "../../assets/images/imagesubject.png";
import enrolment from "../../assets/images/enrolmentfeatureimage.png";
import lesson from "../../assets/images/lessonfeatureimage.png";
import schoolplan from "../../assets/images/schoolfeatureimage.png";
import leave from "../../assets/images/leavefeatureimage.png";
import integration from "../../assets/images/integrationfeatureimg.png";
import assesment from "../../assets/images/assesmentimage.png";

const index = () => {
  const [value, setValue] = useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.requestheader}`}>
              <Grid container alignItems={"center"}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <h3>Shelf 21 Features</h3>
                  <p>
                    See the list of features you have access to as a premium
                    user of shelf 21. Empowering Schools, Engaging Students, and
                    Connecting Parents, all in one platform.
                  </p>
                </Grid>
                {/* <Grid item lg={3} md={3} sm={3} xs={4}>
                  <div
                    className={`${styles.explore} flex align-item-center gap-8 justify-end`}
                  >
                    <Link to="https://st-app.shelf21.com/auth/signup" target="_blank">Sign up to explore all</Link>
                    <div>
                      <NorthEastIcon className="light-blue margin-top-10" />
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
            <div>
              <TabContext value={value}>
                <Box
                  sx={{
                    maxWidth: "787px",
                    marginTop: "54px",
                    bgcolor: "#E6F0FF",
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "30px",
                    color: "#0065FF"
                  }}
                >
                  <Tabs
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { display: "none" }
                    }}
                    value={value}
                    variant={isSmallScreen ? "scrollable" : "standard"}
                    scrollButtons={isSmallScreen ? "auto" : false}
                    centered
                    sx={{
                      "& MuiTabs": {
                        justifyContent: "space-between !important"
                      },
                      "& .MuiTab-root.Mui-selected": {
                        background: "#0065FF",
                        color: "#FFF",
                        borderRadius: "8px",
                        border: "none"
                      },
                      "& button": {
                        color: "#0065FF",
                        fontSize: "16px",
                        fontWeight: "700",
                        padding: "0px",
                        width: "243px"
                      },
                      "& button:hover": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:active": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:focus": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:not(:last-child)": {
                        marginRight: "8px"
                      }
                    }}
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        "& .MuiTab-label": { textTransform: "lowercase" }
                      }}
                      label="All Features"
                      value="1"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        "& .MuiTab-label": { textTransform: "lowercase" }
                      }}
                      label="Adminstrative Feature"
                      value="2"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        "& .MuiTab-label": { textTransform: "lowercase" }
                      }}
                      label="Academic Features"
                      value="3"
                    />
                  </Tabs>
                </Box>
                <TabPanel
                  sx={{ padding: "0px", marginBottom: "40px" }}
                  value="1"
                >
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#0065FF"
                    title="FEATURE"
                    image={FeatureImg}
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                    features={[
                      "Get daily student Attendance",
                      "Send Attendance report to parents",
                      "Overview of overall attendance record of students"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF6E8"
                    fontColor="#F3A218"
                    title="FEATURE"
                    image={lesson}
                    featuretitle="Lesson Notes"
                    featuredesc="Create and view detailed lesson notes for each class, including topics, teaching methods, learning objectives, goals, and required materials"
                    features={[
                      "Create lesson notes for each class.",
                      "Organize topics and teaching methods for efficient lesson planning.",
                      "Review and update notes anytime."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    image={leave}
                    featuretitle="Leave Management"
                    featuredesc="Easily manage employee leave by choosing who can take leave, what types of leave they can take, and how long they can be away"
                    features={[
                      "Manage employee leave requests effortlessly",
                      "Provide multiple leave options for staff",
                      "Track leave statuses in real-time"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={assesment}
                    featuretitle="Assessment"
                    featuredesc="Create and manage assignments for each class, review pending exam papers, and set a grading system tailored to your school."
                    features={[
                      "Assignments: View all ongoing and submitted assignments.",
                      "Examinations: Access pending exam papers awaiting approval.",
                      "Set custom grading systems for your school."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    image={schoolplan}
                    featuretitle="School Plans"
                    featuredesc="Easily manage your school’s academic terms and sessions, ensuring smooth operations and keeping track of important dates throughout the year."
                    features={[
                      "School Terms: Manage and adjust your school's terms.",
                      "School Sessions: Create and oversee sessions with ease.",
                      "School Calendar: Set student plans and create new schedules."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={enrolment}
                    featuretitle="School Enrollments"
                    featuredesc="Easily manage enrollment and applications, enabling parents to apply and enrol their children."
                    features={[
                      "Manage student applications and enrollment processes smoothly.",
                      "View and track all ongoing applications.",
                      "Set enrollment dates, add payment details, and create forms easily."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#013EBA"
                    title="FEATURE"
                    featuretitle="Integrations"
                    image={integration}
                    features={[
                      "Seamlessly integrate with payment platforms like Paystack, Stripe, Flutterwave, and PayPal.",
                      "Connect to Mailchimp for streamlined communication."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF6E8"
                    fontColor="#F3A218"
                    title="FEATURE"
                    image={subjectimg}
                    featuretitle="Subject"
                    features={[
                      "Track all subjects currently offered.",
                      "Assign teachers to specific subjects with ease."

                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={classImg}
                    featuretitle="Class Activities"
                    featuredesc="Take full control of your class/subject setup. Create classes, timetables, and assign subjects in just a few clicks. "
                    features={[
                      "View and manage all classes in your school. Add or remove classes anytime.",
                      "Seamlessly Manage your classes across different sessions and terms",
                      "Effectively organise your classes and subjects with time tables"
                    ]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: "0px", marginBottom: "40px" }}
                  value="2"
                >
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={leave}
                    featuretitle="Leave Management"
                    featuredesc="Easily manage employee leave by choosing who can take leave, what types of leave they can take, and how long they can be away"
                    features={[
                      "Manage employee leave requests effortlessly",
                      "Provide multiple leave options for staff",
                      "Track leave statuses in real-time"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    image={assesment}
                    featuretitle="Assessment"
                    featuredesc="Create and manage assignments for each class, review pending exam papers, and set a grading system tailored to your school."
                    features={[
                      "Assignments: View all ongoing and submitted assignments.",
                      "Examinations: Access pending exam papers awaiting approval.",
                      "Set custom grading systems for your school."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={schoolplan}
                    featuretitle="School Plans"
                    featuredesc="Easily manage your school’s academic terms and sessions, ensuring smooth operations and keeping track of important dates throughout the year."
                    features={[
                      "School Terms: Manage and adjust your school's terms.",
                      "School Sessions: Create and oversee sessions with ease.",
                      "School Calendar: Set student plans and create new schedules."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={enrolment}
                    featuretitle="School Enrollments"
                    featuredesc="Easily manage enrollment and applications, enabling parents to apply and enrol their children."
                    features={[
                      "Manage student applications and enrollment processes smoothly.",
                      "View and track all ongoing applications.",
                      "Set enrollment dates, add payment details, and create forms easily."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={integration}
                    featuretitle="Integrations"
                    features={[
                      "Seamlessly integrate with payment platforms like Paystack, Stripe, Flutterwave, and PayPal.",
                      "Connect to Mailchimp for streamlined communication."
                    ]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: "0px", marginBottom: "40px" }}
                  value="3"
                >
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#0065FF"
                    title="FEATURE"
                    image={FeatureImg}
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                    features={[
                      "Get daily student Attendance",
                      "Send Attendance report to parents",
                      "Overview of overall attendance record of students"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF6E8"
                    fontColor="#F3A218"
                    title="FEATURE"
                    image={lesson}
                    featuretitle="Lesson Notes"
                    featuredesc="Create and view detailed lesson notes for each class, including topics, teaching methods, learning objectives, goals, and required materials"
                    features={[
                      "Create lesson notes for each class.",
                      "Organize topics and teaching methods for efficient lesson planning.",
                      "Review and update notes anytime."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={subjectimg}
                    featuretitle="Subject"
                    features={[
                      "Track all subjects currently offered.",
                      "Assign teachers to specific subjects with ease.",
                      "Effortlessly manage class and subject setups"

                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={classImg}
                    featuretitle="Class Activities"
                    featuredesc="Take full control of your class/subject setup. Create classes, timetables, and assign subjects in just a few clicks. "
                    features={[
                      "View and manage all classes in your school. Add or remove classes anytime.",
                      "Seamlessly Manage your classes across different sessions and terms",
                      "Effectively organise your classes and subjects with time tables"
                    ]}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
